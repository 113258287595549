import React, { useEffect, useState } from "react";

import SearchBar from "../../components/SearchBar/SearchBar";
import Carousel from "../../components/Carousel/Carousel";

import { useDispatch } from "react-redux";
import { cleanSearch } from "../../actions/searchActions";

import Axios from "../../services/Api";
import app_params from "../../config/params";
// import { library_info } from "../../data/homeInfo";
import "./HomeScreen.scss";

const HomeScreen = (props) => {
  const [lastAcquisitions, setLastAcquisitions] = useState({
    records: [],
    hasDetailPage: false,
  });
  // const [visitCount, setVisitCount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cleanSearch());
  }, [dispatch]);

  useEffect(() => {
    Axios.GetLatestAcquisitions()
      .then((res) => res.data)
      .then((data) =>
        setLastAcquisitions(
          data?.success ? data : { records: [], hasDetailPage: false }
        )
      )
      .catch((e) => {
        console.log(e);
        setLastAcquisitions({ records: [], hasDetailPage: false });
      });

    // Axios.GetVisits()
    //   .then((res) => res.data)
    //   .then((data) => setVisitCount(data?.success ? data?.count : 0))
    //   .catch((e) => {
    //     console.log(e);
    //     setVisitCount(0);
    //   });
  }, []);

  return (
    <section className="container">
      <div className="bg-white mx-0 mx-md-3 mt-3 mb-2 pb-4 shadow-sm rounded">
        <div className="row mb-3">
          <div className="col-12 d-flex justify-content-center">
            <img className="img-fluid" src={`${app_params.basepath}/assets/ets_header.jpg`} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <nav className="nav border-bottom">
              <a className="nav-link active" href={`${app_params.basepath}`}>Portada</a>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Nosotros</a>
                <ul className="dropdown-menu">
                  <li><a className="dropdown-item" href="https://bibliotecaescueladetrabajosocial.usac.edu.gt/index.php/historia/" target="_blank" rel="noreferrer">Historia</a></li>
                  <li><a className="dropdown-item" href="https://bibliotecaescueladetrabajosocial.usac.edu.gt/index.php/nosotros/" target="_blank" rel="noreferrer">Fundamentos Filosóficos</a></li>
                </ul>
              </li>
              <a className="nav-link" href="https://bibliotecaescueladetrabajosocial.usac.edu.gt/wp-content/uploads/2020/08/NormativoBibliotecaETS.pdf" target="_blank" rel="noreferrer">Normativo</a>
              <a className="nav-link" href="#" target="_blank" rel="noreferrer">Catálogos</a>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Catálogos</a>
                <ul className="dropdown-menu">
                  <li><a className="dropdown-item" href="https://bibliotecaescueladetrabajosocial.usac.edu.gt/index.php/catalogo-automatizado/" target="_blank" rel="noreferrer">Catálogo automatizado</a></li>
                  <li><a className="dropdown-item" href="https://bibliotecaescueladetrabajosocial.usac.edu.gt/index.php/catalogo-biblioteca-central/" target="_blank" rel="noreferrer">Catálogo Biblioteca Central</a></li>
                </ul>
              </li>
              <a className="nav-link" href="https://bibliotecaescueladetrabajosocial.usac.edu.gt/index.php/biblioteca-virtual/" target="_blank" rel="noreferrer">Biblioteca Virtual</a>
              <a className="nav-link" href="https://bibliotecaescueladetrabajosocial.usac.edu.gt/index.php/contact-us/" target="_blank" rel="noreferrer">Contactos</a>
              <a className="nav-link" href="https://bibliotecaescueladetrabajosocial.usac.edu.gt/index.php/enlaces/" target="_blank" rel="noreferrer">Enlaces</a>
            </nav>
          </div>
        </div>
      </div>

      <div className="bg-white mx-0 mx-md-3 mt-3 mb-3 px-3 px-md-4 px-xl-5 py-4 shadow-sm rounded">
        <div className="row col-12 d-flex justify-content-center mb-2">
          <div className="col-12 col-lg-8 col-xl-6">
            <form action={`${app_params.basepath}/query`}>
              <SearchBar />
            </form>
          </div>
        </div>
        <div className="text-center">
          <a href={`${app_params.basepath}/advancedsearch`}>
            Búsqueda avanzada
          </a>
        </div>
      </div>

      <div className="bg-white mx-0 mx-md-3 mt-3 mb-3 px-3 px-md-4 px-xl-5 shadow-sm rounded">
        <div className="row mt-3">
          <div className="col-12 d-flex justify-content-center">
            <div id="carouselExampleIndicators" className="carousel slide carouselContainer" data-bs-ride="carousel">
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={`${app_params.basepath}/assets/carousel/ets_carousel_1.png`} className="d-block w-100 carouselImg" alt="ets_carousel_1" />
                </div>
                <div className="carousel-item">
                  <img src={`${app_params.basepath}/assets/carousel/ets_carousel_2.png`} className="d-block w-100 carouselImg" alt="ets_carousel_2" />
                </div>
                <div className="carousel-item">
                  <img src={`${app_params.basepath}/assets/carousel/ets_carousel_3.jpg`} className="d-block w-100 carouselImg" alt="ets_carousel_3" />
                </div>
                <div className="carousel-item">
                  <img src={`${app_params.basepath}/assets/carousel/ets_carousel_4.jpg`} className="d-block w-100 carouselImg" alt="ets_carousel_4" />
                </div>
                <div className="carousel-item">
                  <img src={`${app_params.basepath}/assets/carousel/ets_carousel_5.jpg`} className="d-block w-100 carouselImg" alt="ets_carousel_5" />
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>


      <div className="bg-white mx-0 mx-md-3 mt-3 mb-2 px-3 px-md-4 px-xl-5 py-4 shadow-sm rounded">
        <div className="row mb-2">
          <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center btn_card">
            <a target="_blank" rel="noreferrer" href="http://bibliotecakohatsocial.usac.edu.gt/">
              <img className="img-fluid" src={`${app_params.basepath}/assets/menu/btn_catalogo_automatizado.png`} alt="btn" />
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center btn_card">
            <a target="_blank" rel="noreferrer" href="http://biblos.usac.edu.gt/opac/">
              <img className="img-fluid" src={`${app_params.basepath}/assets/menu/btn_catalogo_bibcentral.png`} alt="btn" />
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center btn_card">
            <a target="_blank" rel="noreferrer" href="http://www.repositorio.usac.edu.gt/">
              <img className="img-fluid" src={`${app_params.basepath}/assets/menu/btn_repo_inst_usac.png`} alt="btn" />
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center btn_card">
            <a target="_blank" rel="noreferrer" href="https://bibliotecaescueladetrabajosocial.usac.edu.gt/index.php/bases-de-datos-arbitradas/">
              <img className="img-fluid" src={`${app_params.basepath}/assets/menu/btn_bases_datos_arbitradas.png`} alt="btn" />
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center btn_card">
            <a target="_blank" rel="noreferrer" href="https://www.uv.mx/dgbuv/">
              <img className="img-fluid" src={`${app_params.basepath}/assets/menu/btn_u_veracruzana.png`} alt="btn" />
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center btn_card">
            <a target="_blank" rel="noreferrer" href="https://www.uv.mx/bvirtual/recursos-acceso-abierto/">
              <img className="img-fluid" src={`${app_params.basepath}/assets/menu/btn_u_veracruzana_openaccess.png`} alt="btn" />
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center btn_card">
            <a target="_blank" rel="noreferrer" href="https://www.uv.mx/bvirtual/biblioteca-desde-casa/">
              <img className="img-fluid" src={`${app_params.basepath}/assets/menu/btn_u_veracruzana_desde_casa.png`} alt="btn" />
            </a>
          </div>
        </div>
      </div>
      <Carousel
        title={"Novedades"}
        items={lastAcquisitions?.records}
        hasDetailPage={lastAcquisitions?.hasDetailPage}
      />
      <div className="bg-white mx-0 mx-md-3 mt-3 mb-3 shadow-sm rounded">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <img className="img-fluid" src={`${app_params.basepath}/assets/ets_footer.png`} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeScreen;
