import React from "react";
import dateFormat from "dateformat";

import app_params from "../../config/params";

const Footer = (props) => {
  var now = new Date();
  return (
    <footer className={`text-center text-lg-start theme-footer`}>
      <section className="border-bottom">
        <div className="container text-center text-md-start mt-5">
          <div className="row">
            <div className="col-12 col-lg-6 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contacto</h6>
              <p>
                <i className="fas fa-home me-3"></i> Eidificio S1 primer nivel, Ciudad Universitaria zona 12
              </p>
              <p>
                <a href="mailto:bibliotecats@usac.edu.gt">
                  <i className="fas fa-envelope me-3"></i>bibliotecats@usac.edu.gt
                </a>
              </p>
              <p>
                <a href="tel:24188850">
                  <i className="fas fa-phone-alt me-3"></i>
                  (502) 24188850 Ext. 114
                </a>
              </p>
            </div>
            <div className="col-12 col-lg-6 mx-auto mb-4 text-center">
              <h6 className="text-uppercase fw-bold mb-4">
                Biblioteca Joaquín Noval Escuela de Trabajo Social USAC
              </h6>
              <p>Síguenos en nuestras redes sociales</p>
              <div>
                <a
                  href="www.bibliotecaescueladetrabajosocial.usac.edu.gt"
                  className="me-4 text-reset"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="fa fa-globe" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="d-lg-flex flex-row justify-content-center p-4">
        <div className="d-flex flex-row justify-content-center mx-lg-5">
          <span className="glifos-font">GLIFOS - library</span>
          <span className="ms-1">ver. {app_params.version}</span>
        </div>
        <div className="d-flex flex-row justify-content-center mt-3 mt-lg-0 mx-lg-5">
          Copyright &copy; 2000 - {dateFormat(now, "yyyy")} Infolib, S.A.
        </div>
        <div className="d-flex flex-column justify-content-center mt-3 mt-lg-0 mx-lg-5">
          <div className="d-flex flex-row justify-content-center">
            <a href="https://glifos.com" target="_blank" rel="noreferrer">
              <img
                src={`${app_params.basepath}/assets/logos/poweredByGLIFOS.png`}
                alt="poweredByGLIFOS"
                width="63"
                height="30"
                className="rounded"
              />
            </a>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
